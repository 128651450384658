<template>
  <h2 class="page-title">Login</h2>
  <form v-on:submit.prevent="login">
    <p class="error" v-if="error">{{ error }}</p>
    <div class="form-group">
      <div class="form-input">
        <input type="email" v-model="email" placeholder="Enter Email" v-bind:class="[emailError ? 'error' : '']">
        <div class="form-error" v-if="emailError">{{ emailError }}</div>
      </div>
      <div class="form-input">
        <input type="password" v-model="password" placeholder="Enter Password" v-bind:class="[passwordError ? 'error' : '']">
        <div class="form-error" v-if="passwordError">{{ passwordError }}</div>
      </div>
    </div>
    <div class="form-input form-actions">
      <button class="btn" type="submit">Login</button>
    </div>
    <router-link to="/forgot-password">Forgot Password?</router-link>
  </form>
</template>

<script>
import AuthService from '../services/auth.service'
import store from '../store.js'
import validator from '../services/validator'

export default {
  name: 'Login',
  data () {
    return {
      error: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: ''
    }
  },
  created () {
    if (store.isLoggedIn) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    validate: function () {
      this.emailError = ''
      this.passwordError = ''
      if (validator.isEmpty(this.email)) {
        this.emailError = 'Email is required.'
        return false
      }
      if (!validator.isValidEmail(this.email)) {
        this.emailError = 'Email is not valid.'
        return false
      }
      if (validator.isEmpty(this.password)) {
        this.passwordError = 'Password is required.'
        return false
      }
      return true
    },
    login: function () {
      if (this.validate()) {
        AuthService
          .login({
            email: this.email,
            password: this.password
          })
          .then(response => {
            if (response.error) {
              this.error = response.error
            } else {
              this.error = ''
              this.$router.push('/dashboard')
            }
          })
      }
    }
  }
}
</script>
